export default function avatar(state = {}, action = {}) {
    switch (action.type) {
        case "GET_AVATAR":
            return action.avatar;
        case "RESET_AVATAR":
            return {};
        case "SIGN_OUT":
            return {};
        default:
            return state;
    }
}