import React, {useState} from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {Container} from "semantic-ui-react";
// Components
import AppHeaderBrand from "./HeaderBrand";
import HeaderButton from "./HeaderButton";
import HeaderOverlay from "./HeaderOverlay";
import AppHeaderMenu from "./HeaderMenu";

function Header({user, check, location, history}) {

    const [data, setData] = useState({
        open: false
    });

    function _onToggle() {
        setData({
            ...data,
            open: !data.open
        });
    }

    return (
        <>
            <AppHeader user={user} check={check}>
                <AppContainer>
                   <AppHeaderBrand/>
                    {
                        location.pathname === "/salaries" ?
                            null
                            :
                            <>
                                <HeaderButton onToggle={_onToggle}/>
                                <HeaderOverlay onToggle={_onToggle} open={data.open}/>
                                <AppHeaderMenu location={location} history={history} open={data.open}/>
                            </>
                    }
                </AppContainer>
            </AppHeader>
        </>
    );
}

const AppContainer = styled(Container).attrs({
    className: "app-container-css"
})`
    &.app-container-css {
        display: flex !important;
        height: 100% !important;
        align-items: center;
        @media (max-width: 768px) {
            flex: 1;
            padding: 0 1rem;
            justify-content: space-between;
            margin: 0 !important;
        }
    }
`;

const AppHeader = styled.nav`
   height: 48px;
   width: 100%;
   display: flex;
   align-items: center;
   position: sticky !important;
   top: 0;
   z-index: 1000;
   background-color: ${
        props =>
            props.user.roleId === 1 ?
                "#222"
                :
                (props.user.roleId === null) ?
                    (props.check.role === 2) ?
                        "#232735"
                        :
                        "#222"
                    :
                    "#232735"
    };
`;

function mapStateToProps(state) {
    return {
        user: state.user,
        check: state.check
    }
}

export default connect(mapStateToProps)(Header);