export default function dashboard (state = {}, action = {}) {
    switch (action.type) {
        case "GET_DASHBOARD_DATA":
            return {
                ...state,
                ...action.dashboard
            };
        case "RESET_DASHBOARD":
            return {};
        case "SIGN_OUT":
            return {};
        default:
            return state;
    }
}