export default function wizard(state = {}, action = {}) {
    switch (action.type) {
        case "GET_WIZARD_PROFILE":
            return {
                ...state,
                ...action.wizard
            };
        case "SIGN_OUT":
            return {};
        default:
            return state;
    }
}