import axios from "axios";
import {API} from "../../constant";

//Get footer data
export const getFooter = () => async (dispatch) => {
    try {
        const payload = await axios.get(API.modulesFooter,
            { headers: {}})
            .then(res => res.data);
        if(payload.status === "ok") {
            const data = {
                status: payload.status,
                categories: payload.data.categories,
                cities: payload.data.cities
            };
            dispatch(getFooterData(data));
        }
    } catch(error) {
        console.log(error);
    }
};


//Action type
export const getFooterData = (data) => ({
    type: "GET_FOOTER_DATA",
    data
});