import axios from "axios";
import decode from 'jwt-decode';
import {API} from "../../constant";

// Sign In
export const signIn = (token) => async dispatch => {
    const userData = decode(token);
    try {
        const userPayload = await axios.post(API.getUserRole,
            {uid: userData.user_id},
            {
                headers: {"Authorization": "Bearer " + token}
            }).then(res => res.data);

        if (userPayload.status === "ok") {
            const generalUserData = {
                accountId: userPayload.data.user.account_id,
                profileId: userPayload.data.user.profile_id,
                picture: userPayload.data.user.avatar,
                name: userPayload.data.user.name,
                email: userPayload.data.user.email,
                uid: userData.user_id,
                token: token,
                hireStatus: userPayload.data.user.hire_status,
                roleId: userPayload.data.role_id,
                isActive: userPayload.data.user.is_active
            };
            localStorage.setItem("user-data", JSON.stringify(generalUserData));
            localStorage.setItem("expires_in", JSON.stringify(userData.exp));
            localStorage.setItem("token", JSON.stringify(token));
            dispatch(signInDispatch(generalUserData))
        } else {
            console.log("Error from api");
        }
    } catch(error) {
        console.log(error);
    }
};

export const signInDispatch = user => ({
    type: "SIGN_IN",
    user
});

// Sign Out
export const signOut = () => dispatch => {
    // let keysToRemove = ["user-online", "check-role"];
    //
    // keysToRemove.forEach(key =>
    //     localStorage.removeItem(key)
    // );

  // localStorage.removeItem("user-data");
  // localStorage.removeItem("check-role");
    localStorage.clear();
    dispatch(signOutDispatch());
};

const signOutDispatch = () => ({
    type: "SIGN_OUT"
});