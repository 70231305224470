export default function getRecruiter(state = {}, action= {}) {
    switch (action.type) {
        case "GET_DATA_RECRUITER_PROFILE":
            return action.getRecruiter;
        case "RESET_RECRUITERS":
            return {};
        case "SIGN_OUT":
            return {};
        default:
            return state;
    }
}