export default function data(state= {}, action = {}) {
    switch (action.type) {
        case "GET_PROFILE_DATAS":
            return {
                ...state,
                ...action.data
            };
        case "SIGN_OUT":
            return {};
        default:
            return state;
    }
}