export default function salaries(state = {}, action= {}) {
    switch (action.type) {
        case "DATA_SALARIES":
            return action.salaries;
        case "RESET_SALARIES":
            return {};
        case "SIGN_OUT":
            return {};
        default:
            return state;
    }
}