import React from "react";
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";

function UserOnline({ user, component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={props => user.uid ? <Component {...props}/> : <Redirect to="/" /> }
        />
    );
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(UserOnline);