import React, {useState} from 'react';
import {Button, Divider, Dropdown, Grid, Header, Image} from "semantic-ui-react";
import styled from "styled-components";
// Images
import userAvatarImage from "../../../assets/img/user.png";
import {Link} from "react-router-dom";
import verified from "../../../assets/img/recruiter_verified.svg";
import top from "../../../assets/img/recruiter_top.svg";

function HeaderDropdown({user, check, signOut, history}) {

    const [data, setData] = useState({
        open: false,
    });

    function onOpen() {
        setData({
            ...data,
            open: !data.open
        })
    }

    const avatarImage = (user.picture)
        ?
        <Image src={user.picture} avatar/>
        :
        <Image src={userAvatarImage} avatar/>
    ;

    const trigger = (
        <AppHeaderText user={user} check={check} open={data.open} isActive={user.isActive}>
            {
                user.hireStatus === 1 ?
                    <span className="badge-top">
                        <Image src={verified}/>
                    </span>
                    :
                    user.hireStatus === 2 ?
                        <span className="badge-top">
                           <Image src={top}/>
                        </span>
                        :
                        null
            }
            <div>
                {avatarImage}
            </div>
            {
                user.roleId === 1 || check.role === 1 ?
                    null
                    :
                    <span className="badge"/>
            }
        </AppHeaderText>
    );

    function logOut() {
        signOut();
        history.push("/");
    }

    return (
            <AppDropdown
                trigger={trigger}
                open={data.open}
                icon={null}
                onClick={onOpen}
                onClose={onOpen}
                basic
                floating
            >
                <Dropdown.Menu as={Grid} onClick={e => e.stopPropagation()}>
                    <Grid.Row columns={1} centered>
                        <Grid.Column textAlign="center">
                            <div className="logo">
                                {avatarImage}
                            </div>
                            <Header as="h4" icon>
                                {user.name}
                                <Header.Subheader>
                                    {user.email}
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                        <Divider/>
                        <Grid.Column>
                            <div className="link">
                                <AppLink
                                    as={Link}
                                    to={
                                        user.roleId === 1 ?
                                            "/home/profile"
                                            :
                                            user.roleId === null ?
                                                check.role === 2 ?
                                                    "/my/wizard"
                                                    :
                                                    "/home/wizard"
                                                :
                                                "/my/profile"
                                    }
                                    onClick={onOpen}
                                >
                                    My Profile
                                </AppLink>
                            </div>
                        </Grid.Column>
                        <Divider/>
                        <Grid.Column textAlign="center">
                            <Button type="button" onClick={logOut} compact negative>Log out</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Dropdown.Menu>
            </AppDropdown>
    );
}

const AppDropdown = styled(Dropdown).attrs({
    className: "app-dropdown-css"
})`
    &.app-dropdown-css {
       color: #fff;
       height: 100%;
       display: flex;
       align-items: center;
       justify-content: center;
       .logo {
          img {
              margin: 0 !important;
              width: 72px;
              height: 72px;
          }
       }
       i {
          transition: transform .3s;
          transform: rotate(${props => props.open ? "-180deg" : "0deg"}) !important;
       }
       .link {
          text-align: center;
       }
       .icon {
          margin: 0 !important;
       }
       .visible {
          min-width: 210px !important;
          max-width: 210px !important;
          left: auto !important;
          right: 0 !important;
          border-radius: 2px !important;
          button {
             margin: 0 !important;
          }
       }
       @media (max-width: 768px) {
           .visible {
              left: auto !important;
              right: auto !important;
          }
       }
    }
`;

const AppLink = styled(Button).attrs({
    className: "app-link-css"
})`
    &.app-link-css {
        border: 1px solid #dadce0;
        color: #000;
        border-radius: 100px;
        padding: 3px 16px;
        transition: .3s;
        &:hover {
           background-color: #dddddd;
        }
    }
`;

const AppHeaderText = styled.div`
    font-size: 15px;
    position: relative !important;
    div {
        padding: 5px;
        overflow: hidden;
        border-radius: 50%;
        transition: all .3s;
        background-color: ${props => props.open ? "rgba(232,234,237,0.1)" : "inherit"};
        &:hover {
          background-color: rgba(232,234,237,0.1);
        }
    }
    .badge-top {
        position: absolute;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        top: -8px;
        left: 0;
        z-index: 99;
        img {
            width: 100%;
            height: 100%;
            margin-right: 0 !important;
        }
    }
    .badge {
       color: #44b700;
       box-shadow: ${props =>
            props.user.roleId === null ?
                props.check.role === 2 ?
                    "0 0 0 2px #fff"
                    :
                    null
                :
                props.user.roleId === 2 ?
                    "0 0 0 2px #fff"
                    :
                    null
       };
       background-color: ${props => 
           props.user.roleId === null ?
               props.check.role === 2 ?
                   props.user.isActive === 1 ?
                       "#44b700" 
                       :
                       "grey"
                   :
                   null
               :
               props.user.roleId === 2 ?
                   props.user.isActive === 1 ?
                       "#44b700"
                       :
                       "grey"
                   :
                   null
       };
       right: 5px;
       border-radius: 50%;
       bottom: 10px;
       height: 8px;
       width: 8px;
       position: absolute;
       ::after {
            @keyframes zoomIn {
              0% {
                  transform: scale(0);
                  opacity: 1;
              }
              100% {
                  transform: scale(3);
                  opacity: 0;
              }
            }
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            border: ${props => props.isActive === 1 ? "1px solid currentColor" : "none" };
            height: 100%;
            border-radius: 50%;
            animation: ${props => props.isActive === 1 ? "zoomIn 1.2s infinite linear" : "none" };
       }
    }
    img {
       margin: 0 !important;
       width: 36px !important;
       height: 36px !important;
    }
`;

// const AppPopup = styled(Popup).attrs({
//     className: "app-popup-css"
// })`
//     &.app-popup-css {
//         font-size: 12px !important;
//         text-align: center !important;
//     }
// `;


export default HeaderDropdown;