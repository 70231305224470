import React from "react";
import {Dimmer, Loader} from "semantic-ui-react";
import styled from "styled-components";

function Loading() {
    return (
        <AppDimmer active inverted>
            <Loader size="large">
                Loading...
            </Loader>
        </AppDimmer>
    )
}

const AppDimmer = styled(Dimmer).attrs({
    className: "app-dimmer-css"
})`
    &.app-dimmer-css {
        position: fixed !important;
        top: 48px !important;
        padding: 0 !important;
        bottom: 0!important;
        right: 0 !important;
        left: 0 !important;
        z-index: 999;
        background-color: #fff !important;
    }
`;

export default Loading;