export const getRole = (role) => dispatch => {
    const payload = {
        role: Number(role) || null
    };
    localStorage.setItem("check-role", JSON.stringify(payload));
    dispatch(getRoleDispatch(payload));
};

export const getRoleDispatch = (check) => ({
    type: "CHECK_ROLE",
    check
});