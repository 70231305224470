import React from 'react';
import styled from "styled-components";
import {NavLink} from "react-router-dom";
// Images
import {signIn, signOut} from "../../../Redux/actions/user";
import {connect} from "react-redux";
import {Image} from "semantic-ui-react";
import logo from "../../../assets/img/hiree-dark.svg"

function HeaderBrand({user, check}) {
    return (
        <AppNavbarBrand>
            {
                user.uid ?
                    (user.roleId === 1) ?
                        <AppNavLink to="/developers">
                            <Image src={logo}/>
                        </AppNavLink>
                        :
                        (user.roleId === null) ?
                            (check.role === 2) ?
                                <AppNavLink to="/my/wizard">
                                    <Image src={logo}/>
                                </AppNavLink>
                                :
                                <AppNavLink to="/home/wizard">
                                    <Image src={logo}/>
                                </AppNavLink>
                            :
                            <AppNavLink to="/my/dashboard">
                                <Image src={logo}/>
                            </AppNavLink>
                :
                <AppNavLink to="/">
                    <Image src={logo}/>
                </AppNavLink>
            }
        </AppNavbarBrand>
    );
}

const AppNavbarBrand = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 3rem;
    img {
       width: 100%;
       height: auto;
       display: block;
       max-width: 100px;
    }
    @media (max-width: 768px) {
        padding: 0;
    }
`;

const AppNavLink = styled(NavLink).attrs({
    className: "app-nav-link-css"
})`
    &.app-nav-link-css {
        color: #fff;
        font-weight: 900;
        font-size: 1.5em;
        img {
            width: 80px;
        }
    }
`;

function mapStateToProps(state) {
    return {
        user: state.user,
        check: state.check
    }
}

export default connect(mapStateToProps, {signIn, signOut})(HeaderBrand);