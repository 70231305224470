export default function online(state = {}, action= {}) {
    switch (action.type) {
        case "GET_CHECK_ONLINE":
            return action.online;
        case "RESET_ONLINE":
            return {};
        case "SIGN_OUT":
            return {};
        default:
            return state;
    }
}