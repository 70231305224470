export default function myJobs(state = {}, action= {}) {
    switch (action.type) {
        case "GET_JOBS":
            return action.job;
        case "RESET_RECRUITER_DATA":
            return {};
        case "SIGN_OUT":
            return {};
        default:
            return state;
    }
}