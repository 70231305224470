export default function vacancies(state = {}, action = {}) {
    switch (action.type) {
        case "GET_VACANCIES":
            return action.vacancies;
        case "RESET_VACANCIES":
            return {};
        case "SIGN_OUT":
            return {};
        default:
            return state;
    }
}