import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {signOut} from "../../../Redux/actions/user";
import {NavLink} from "react-router-dom";
//Components
import HeaderDropdown from "./HeaderDropdown";
import firebaseDB from "../../../firebaseDatabase";
import {Label} from "semantic-ui-react";
import {API} from "../../../constant";

function HeaderMenu({open, check, signOut, user, location, history}) {

    const [inboxNew, setInboxNew] = useState(0);

    const userData = JSON.parse(localStorage.getItem("user-data"));

    const handleInboxChange = useCallback(() => {
        if (userData) {
            firebaseDB.ref().child(API.firebaseEnv + "/newMessages/inboxCount" + userData.accountId).on("value", snap => {
                if(snap.val() === 0) {
                    setInboxNew(null);
                } else {
                    setInboxNew(snap.val());
                }
            });
        }
    },[userData]);

    useEffect(() => {
        handleInboxChange()
    }, [handleInboxChange]);

    return (
        <AppNavbarMenu open={open} user={user} check={check}>
            {
                user.uid ?
                    <>
                        {
                            user.roleId === null ?
                                (check.role === 2) ?
                                    <li>
                                        <AppNavLink to="/jobs" exact>Jobs</AppNavLink>
                                    </li>
                                    :
                                    <li>
                                        <AppNavLink to="/developers" exact>Candidates</AppNavLink>
                                    </li>
                                :
                                (user.roleId === 1) ?
                                    <>
                                        <li>
                                            <AppNavLink to="/messages" exact>
                                                {/*<Icon name={inboxNew > 0 ? "mail" : "envelope open"}/>*/}
                                                Inbox
                                                {
                                                    inboxNew > 0 ?
                                                        <>&nbsp;
                                                            <AppLabel size="mini" inbox={inboxNew} color={inboxNew > 0 ? "blue" : null}>
                                                                {inboxNew ? inboxNew : null}
                                                            </AppLabel>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </AppNavLink>
                                        </li>
                                        <li><AppNavLink to="/developers" exact>Candidates</AppNavLink></li>
                                        <li><AppNavLink to="/home/jobs" exact>Jobs</AppNavLink></li>
                                    </>
                                    :
                                    <>
                                        <li>
                                            <AppNavLink to="/messages" exact>
                                                {/*<Icon name={inboxNew > 0 ? "mail" : "envelope open"}/>*/}
                                                Inbox
                                                {
                                                    inboxNew > 0 ?
                                                    <>&nbsp;
                                                        <AppLabel size="mini" inbox={inboxNew} color={inboxNew > 0 ? "blue" : null}>
                                                            {inboxNew ? inboxNew : null}
                                                        </AppLabel>
                                                    </>
                                                    :
                                                    null
                                                }
                                            </AppNavLink>
                                        </li>
                                        <li><AppNavLink to="/jobs" exact>Jobs</AppNavLink></li>
                                    </>
                        }
                        <li className="dropdown">
                            <HeaderDropdown user={user} check={check} history={history} signOut={signOut}/>
                        </li>
                    </>
                    :
                    <>
                        {
                            location.pathname === "/auth/login" || location.pathname === "/" ?
                                null
                                :
                                <>
                                    <li><AppNavLink to="/developers" exact>Candidates</AppNavLink></li>
                                    <li><AppNavLink to="/jobs" exact>Jobs</AppNavLink></li>
                                </>
                        }
                        <li><AppNavLink to="/auth/login" exact>Login</AppNavLink></li>
                    </>
            }
        </AppNavbarMenu>
    );
}

const AppNavbarMenu = styled.ul`
    height: 100%;
    display: flex;
    list-style: none;
    margin: 0;
    flex: 1;
    position: relative;
    .dropdown {
       min-width: auto;
    }
    li {
      min-width: 120px;
      height: 100%;
      text-align: center;
      i {
        height: auto !important;
      }
    }
    li:last-child {
      position: absolute;
      align-items: center;
      justify-content: center;
      right: 0;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        transition: left .5s;
        width: 80%;
        position: fixed;
        padding: 0;
        left: ${props => props.open ? "0" : "-80%"};
        top: 48px;
        bottom: 0;
        z-index: 999;
        background-color: ${props =>
            props.user.roleId === 1 ?
                "#222"
                :
                (props.user.roleId === null) ?
                    (props.check.role === 2) ?
                        "#232735"
                        :
                        "#222"
                    :
                    "#232735"
        };
        li {
          height: 48px;
          border-bottom: 1px solid rgba(255,255,255,.2);
          min-width: auto;
          text-align: center;
        }
        li:last-child {
          position: relative;
        }
    }
`;

const AppNavLink = styled(NavLink).attrs({
    className: "app-nav-link-css"
})`
    &.app-nav-link-css {
        color: rgba(255,255,255, .5);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        position: relative;
        .label {
              height: 20px;
              width: 30px;
              display: flex;
              justify-content: center;
              border-radius: 20px;
              align-items: center;
        }
        &::after {
             content: "";
             position: absolute;
             bottom: 0;
             left: 0;
             right: 0;
             display: block;
             height: 4px;
             transform: scale(0);
             transition: transform .3s;
             background-color: #2D9CDB;
        }
        &:hover {
          color: #fff;
          .label {
              color: #fff;
          }
         
        }
        &.active {
          color: #fff;
          .label {
              color: #fff;
          }
          &::after {
             transform: scale(1);
          }
        }
        @media (max-width: 768px) {
            &.active {
               background-color: rgba(255,255,255,.2);
            }
        }
    }
`;

const AppLabel = styled(Label).attrs({
    className: "app-label-css"
})`
    &.app-label-css {
        font-size: 14px !important;
        font-weight: normal;
        background-color: ${props => props.inbox > 0 ? "#2185d0" : "transparent"};
        color: ${props => props.inbox > 0 ? "#fff" : "rgba(255,255,255, .5)"};
    }
`;

function mapStateToProps(state) {
    return {
        user: state.user,
        check: state.check
    }
}

export default connect(mapStateToProps, {signOut})(HeaderMenu);