import React from 'react';
import styled from "styled-components";
import {Icon} from "semantic-ui-react";

function HeaderButton({onToggle}) {
    return (
        <AppNavbarButton onClick={onToggle}>
            <Icon name="sidebar"/>
        </AppNavbarButton>
    );
}

const AppNavbarButton = styled.button`
    display: none;
    @media (max-width: 768px) {
        padding: .5rem;
        display: block;
        border-radius: 4px;
        outline: 0;
        border: 1px solid rgba(255,255,255,.3);
        background-color: transparent;
        color: #fff;
        i {
           margin-right: 0 !important;
        }
    }
`;

export default HeaderButton;