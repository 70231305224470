import {combineReducers} from "redux";
import user from "./user";
import check from "./check";
import wizard from "./wizard";
import messages from "./messages";
import data from "./data";
import online from "./online";
import avatar from "./avatar";
import switchTo from "./switch";
import setRecruiter from "./setRecruiter";
import getRecruiter from "./getRecruiter";
import dashboard from "./dashboard";
import getCandidate from "./getCandidate";
import setCandidate from "./setCandidate";
import vacancies from "./vacancies";
import myJobs from "./myJobs";
import salaries from "./salaries";
import footer from "./footer";

export default combineReducers({
    user,
    check,
    wizard,
    data,
    online,
    avatar,
    switchTo,
    getRecruiter,
    setRecruiter,
    getCandidate,
    setCandidate,
    messages,
    dashboard,
    myJobs,
    vacancies,
    salaries,
    footer
});