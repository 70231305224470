import React, {useEffect} from 'react';
import {Divider, Grid, Image, List, Popup} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import styled from "styled-components";
import {getFooter} from "../../Redux/actions/footer";
import logoLight from "../../assets/img/logo-light.svg";
import whatsApp from "../../assets/img/whatsapp.svg";
import telegram from "../../assets/img/telegram.svg";
import facebook from "../../assets/img/facebook.svg";
import instagram from "../../assets/img/instagram.svg";
import question from "../../assets/img/question.svg";

function Footer({user, check, getFooter, footer, location}) {

    const currentTime = new Date();
    const year = currentTime.getFullYear();

    useEffect(() => {
        getFooter();
    }, [getFooter]);

    return (
        <>
            {
                !user.id ?
                    location.pathname === "/salaries" || location.pathname === "/auth/login" ?
                        null
                        :
                        <AppFooter>
                            <Divider/>
                            <Grid container stackable>
                                <Grid.Row columns={3}>
                                    <Grid.Column width={5}>
                                        <div className="company-email">
                                            <a href="mailto:ruslan.musayev@bitcat.agency" className="mail-to" >ruslan.musayev@bitcat.agency</a>
                                            <Popup
                                                inverted
                                                position="top center"
                                                size='tiny'
                                                style={{textAlign: "center"}}
                                                content="If you have any questions, please contact to us"
                                                trigger={<Image src={question} size='mini'/>}
                                            />
                                        </div>
                                        <List>
                                            {
                                                user.uid ?
                                                    <>
                                                        <List.Item>
                                                            {
                                                                user.roleId === 1 ?
                                                                    <>
                                                                        <Link to='/home/profile/settings'>Settings</Link>
                                                                        <Link to='/home/profile'>My profile</Link>
                                                                    </>
                                                                    :
                                                                    user.roleId === null ?
                                                                        check.role === 2 ?
                                                                            <Link to='/my/wizard'>My profile</Link>
                                                                            :
                                                                            <Link to='/home/wizard'>My profile</Link>
                                                                        :
                                                                        <Link to='/my/profile'>My profile</Link>
                                                            }
                                                        </List.Item>
                                                    </>
                                                    :
                                                    null
                                            }
                                            <List.Item><Link to='/salaries'>Salaries</Link></List.Item>
                                            <List.Item><Link to='/pricing'>Terms of Use</Link></List.Item>
                                            <List.Item><Link to='/help/privacy'>Privacy</Link></List.Item>
                                        </List>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <h6>Jobs by city</h6>
                                        <List>
                                            {
                                                footer.cities && footer.cities.map((d, i) => {
                                                    return (
                                                        <List.Item key={i}><Link to={`/jobs?city=${d.name}`}>{d.name}</Link></List.Item>
                                                    )
                                                })
                                            }
                                        </List>
                                    </Grid.Column>
                                    <Grid.Column width={7}>
                                        <h6>By specialty</h6>
                                        <List className='footer-categories'>
                                            {
                                                footer.categories && footer.categories.map((d, i) => {
                                                    return (
                                                        <List.Item key={i}><Link to={`/jobs?category_id=${d.category_id}`}>{d.title}</Link></List.Item>
                                                    )
                                                })
                                            }
                                        </List>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className='app-footer-row'>
                                    <Image src={logoLight} className='footer-logo' size='small'/>
                                    <Image src={whatsApp} className='footer-icon' size='mini'/>
                                    <Image src={telegram} className='footer-icon' size='mini'/>
                                    <Image src={facebook} className='footer-icon' size='mini'/>
                                    <Image src={instagram} className='footer-icon' size='mini'/>
                                </Grid.Row>
                                <p className='year'>© {year} HIREE</p>
                            </Grid>
                        </AppFooter>
                    :
                    null
            }
        </>
    );
}

const AppFooter = styled.div.attrs({
    className: "app-footer"
})`
    &.app-footer {    
      margin-top: auto !important;
      margin-bottom: 1rem;
      .list {
        margin: 0.5em 0 !important;
        .item > a {
          font-size: 0.9em;
          &:hover {
            text-decoration: underline;
          }
        }
        &.footer-categories {    
            max-height: 127px;
            height: 100%;
            list-style: none;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-flow: column wrap;
            flex-flow: column wrap;
            align-items: flex-start;
        }
      }
      .app-footer-row {
          align-items: center;
          padding: 0 0.7rem 0 0.7rem;
          .footer-logo {
            width: 90px;
            margin-right: 30px;
          }
          .footer-icon {
            width: 21px;
            height: 20px;
            margin: 0 5px;
          }        
      }
      .company-email {
        display: flex;
        align-items: center;
        img {
          width: 1.3em;
          height: 1.3em;
          margin-left: 0.7em;
        }
      }
      .year {
        font-size: 0.7em;
        color: #999;
        padding-left: 0.7rem !important;
      }
      .mail-to {
        color: rgba(0,0,0,.87);
        font-weight: bold;
        font-size: 13px; 
        &:hover {
          text-decoration: underline;
        }       
      }
      @media(max-width: 768px) {
        .footer-categories {
          max-height: none !important;
        }
      }
    }
`;

function mapStateToProps(state) {
    return {
        user: state.user,
        check: state.check,
        footer: state.footer
    }
}

const mapDispatchToProps = {
    getFooter: getFooter
};


export default connect(mapStateToProps, mapDispatchToProps)(Footer);