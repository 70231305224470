export default function getCandidate(state = {}, action= {}) {
    switch (action.type) {
        case "GET_DATA_CANDIDATE_PROFILE":
            return action.getCandidate;
        case "RESET_CANDIDATES":
            return {};
        case "SIGN_OUT":
            return {};
        default:
            return state;
    }
}