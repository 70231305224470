import React from "react";
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";

function UserOffline({ user, check, location, component: Component, ...rest}) {

    const urlParams = new URLSearchParams(location.search);
    const typeFromUrl = urlParams.get("type");
    const nextFromUrl = urlParams.get("next");

    return (
        <Route
            {...rest}
            render={props => !user.uid ? <Component {...props}/> :
                user.roleId === 1 ?
                    typeFromUrl && typeFromUrl === "recruiter" ?
                        <Redirect to={nextFromUrl}/>
                        :
                        <Redirect to="/developers"/>
                    :
                    (user.roleId === null) ?
                        (check.role === 2) ?
                            typeFromUrl && typeFromUrl === "candidate" ?
                                <Redirect to={nextFromUrl}/>
                                :
                                <Redirect to="/my/wizard"/>
                            :
                            typeFromUrl && typeFromUrl === "recruiter" ?
                                <Redirect to={nextFromUrl}/>
                                :
                                <Redirect to="/home/wizard"/>
                        :
                        typeFromUrl && typeFromUrl === "candidate" ?
                            <Redirect to={nextFromUrl}/>
                            :
                            <Redirect to="/jobs"/>
                 }
        />
    );
}

function mapStateToProps(state) {
    return {
        user: state.user,
        check: state.check
    }
}

export default connect(mapStateToProps)(UserOffline);