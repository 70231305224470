import React, {lazy, Suspense} from 'react';
import {Route, Switch} from "react-router-dom";
// Routers
import UserOffline from "./Components/Common/Login/UserOffline";
import UserOnline from "./Components/Common/Login/UserOnline";
// Components
import Loading from "./Components/Common/Loading";
import Header from "./Components/Common/Header/Header";
import Footer from "./Components/Common/Footer";
// Common
const AppSalary = lazy(() => import("./Components/Common/SalariesContainer"));
const AppNetwork = lazy(() => import("./Components/Common/NetworkError"));
const AppPrice = lazy(() => import("./Components/Common/Pricing"));
const AppPrivacy = lazy(() => import("./Components/Common/Privacy"));
const Messages = lazy(() => import("./Components/Common/Messages/Messages"));
const MessagesContainer = lazy(() => import("./Components/Common/Messages/MessageUi/MessageContainer"));
const AppHome = lazy(() => import("./Components/Common/Home/Home"));
const AppLogin = lazy(() => import("./Components/Common/Login/Login"));
const AppError = lazy(() => import("./Components/Common/Error"));
const AccountSelect = lazy(() => import("./Components/Common/AccountSelect"));
const AppVacancy = lazy(() => import("./Components/Common/VacancyInformationContainer"));
const AppPostJobContainer = lazy(() => import("./Components/Recruiters/Jobs/PostJobContainer"));
// Recruiter
const AppRecruiter = lazy(() => import("./Components/Recruiters/DevelopersContainer"));
const AppRecruiterJobs = lazy(() => import("./Components/Recruiters/Jobs/Jobs"));
const AppRecruiterProfile = lazy(() => import("./Components/Recruiters/ProfileMenu/Profile"));
const AppRecruiterWizard = lazy(() => import("./Components/Recruiters/Wizard/MainWizard"));
const AppRecruiterAccount = lazy(() => import("./Components/Recruiters/RecruiterAccountContainer"));
// Candidate
const AppDashboard = lazy(() => import("./Components/Candidates/Dashboard/Dashboard"));
const AppCandidateProfile = lazy(() => import("./Components/Candidates/ProfileMenu/Profile"));
const AppJobs = lazy(() => import("./Components/Candidates/JobsMenu/Jobs"));
const AppUserWizard = lazy(() => import("./Components/Candidates/Wizard/MainWizard"));
const AppCandidateAccount = lazy(() => import("./Components/Candidates/CandidateAccountContainer"));

function App({history, location}) {

    return (
        <>
            <Header history={history} location={location}/>
            <Suspense fallback={<Loading/>}>
                <Switch>
                    <UserOffline exact path="/" component={AppHome}/>
                    <UserOffline exact path="/auth/login" component={AppLogin}/>
                    <UserOnline exact path="/my/dashboard" component={AppDashboard}/>
                    <Route exact path="/salaries" component={AppSalary}/>
                    <Route exact path="/pricing" component={AppPrice}/>
                    <Route exact path="/help/privacy" component={AppPrivacy}/>
                    <UserOnline exact path="/messages" component={Messages}/>
                    <UserOnline exact path="/messages/:id" component={MessagesContainer}/>
                    <UserOnline exact path="/network" component={AppNetwork}/>

                    <Route exact path="/jobs" component={AppJobs}/>
                    <Route exact path="/jobs/:id" component={AppVacancy}/>
                    <UserOnline path="/my/profile" component={AppCandidateProfile}/>
                    <UserOnline exact path="/my/wizard" component={AppUserWizard}/>
                    <Route exact path="/r/:id" component={AppRecruiterAccount}/>

                    <Route exact path="/developers" component={AppRecruiter}/>
                    <UserOnline exact path="/home/jobs" component={AppRecruiterJobs}/>
                    <UserOnline exact path="/home/jobs/:id" component={AppPostJobContainer}/>
                    <UserOnline exact path="/home/post_job" component={AppPostJobContainer}/>
                    <UserOnline path="/home/profile" component={AppRecruiterProfile}/>
                    <UserOnline exact path="/home/wizard" component={AppRecruiterWizard}/>
                    <Route exact path="/c/:id" component={AppCandidateAccount}/>

                    <UserOnline exact path="/account_select" component={AccountSelect}/>
                    <Route exact path="/*" component={AppError}/>
                </Switch>
            </Suspense>
            <Footer location={location}/>
        </>
    );
}

export default App;
