export default function setRecruiter(state = {}, action= {}) {
    switch (action.type) {
        case "SET_DATA_RECRUITER_PROFILE":
            return action.setRecruiter;
        case "RESET_SET_RECRUITER":
            return {};
        case "SIGN_OUT":
            return {};
        default:
            return state;
    }
}