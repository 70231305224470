export default function messages ( state = [], action = {}) {
    switch (action.type) {
        case 'IS_LOADING_DATA_MESSAGES':
            return {
                isLoading: true
            };
        case 'IS_LOADING_DATA':
            return {
                isRefresh: true,
                ...state
            };
        case "GET_USER_DIALOGS":
            return {
                ...state,
                list: action.dialogs
            };
        case "RESET_DIALOGS":
            return {};
        case 'SET_MESSAGES_DATA':
            return {
                ...state,
                data: action.messages,
                isLoading: false
            };
        case "RESET":
            return {};
        case 'ADD_USER_MESSAGES':
            state.data.messages.push(action.sendMessage.data);
            return {
                ...state,
                sendMessageStatus: action.sendMessage.status
            };
        case 'GET_PROFILE_SHARE_DATA':
            return {
                ...state,
                sharedData: action.sharedData,
                isRefresh: false,
            };
        case 'SET_EDIT_PROFILE_DATA':
            return {
                ...state,
                statusEdit: action.editData.status,
                mergeSystemMessages: state.data.messages.push(action.editData.edit.message)
            };
        case "CHECK_CANDIDATE_STATUS":
            return {
                ...state,
                ...action.status,
                mergeSystemMessages: state.data.messages.push(action.status.message)
            };
        case "FIREBASE_SET_MESSAGE_DATA":
            state.data !== undefined && state.data.messages.push(action.firebaseData);
            return {
                 ...state
            };
        case "GET_RECRUITER_INFORMATION":
            return {
                ...state,
                recruiterInfo: action.infoData,
                isRefresh: false,
            };
        case "SIGN_OUT":
            return {};
        default:
            return state
    }
}