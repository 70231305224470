import React from 'react';
import styled from "styled-components";

function HeaderOverlay({onToggle, open}) {
    return (
        <AppNavbarOverlay onClick={onToggle} open={open}/>
    );
}

const AppNavbarOverlay = styled.div`
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    @media (max-width: 768px) {
        opacity: ${props => props.open ? 1 : 0};
        visibility: ${props => props.open ? "visible" : "hidden"};
        position: fixed;
        top: 48px;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.6);
        z-index: 99;
    }
`;

export default HeaderOverlay;