import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route} from "react-router-dom";
import {Provider} from "react-redux";
import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import axios from "axios";
import decode from "jwt-decode";
// firebase
import firebase from "firebase";
// Styles
import './index.css';
import "react-toastify/dist/ReactToastify.min.css";
import 'semantic-ui-css/semantic.min.css'
// Components
import App from './App';
// actions
import {signInDispatch} from "./Redux/actions/user";
import { getRoleDispatch } from "./Redux/actions/check";
// reducers
import rootReducers from "./Redux/reducers/rootReducers";
import {toast, ToastContainer} from "react-toastify";

axios.interceptors.request.use(
    request => requestHandler(request),
);

axios.interceptors.response.use(
    response => responseHandler(response)
    , function (error) {
        // Do something with response error
        if (error.response.status === 404) {
            toast.error("Something went wrong :(. Please check your connection or try again!", {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else if(error.response.status === 500) {
            toast.error("Something went wrong :(. Please, check your connection or try again!", {
                position: toast.POSITION.BOTTOM_LEFT
            })
        }
        return Promise.reject(error.response);
    });

const responseHandler = (response) => {
    if (response.data && (response.data.status === "error" || response.data.status === "not_found")) { // error || not_found
        toast.error("Something went wrong :(. Please, check your connection or try again!", {
            position: toast.POSITION.BOTTOM_LEFT
        })
    }
    return response;
};

function axiosCall() {
    return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                user.getIdToken(true)
                    .then(token => {
                        resolve(token);
                    }).catch((error) => {
                        reject(error);
                });
            }
        });
    });
}

const requestHandler = (request) => {
    const userData = JSON.parse(localStorage.getItem("user-data"));
    const exp = JSON.parse(localStorage.getItem("expires_in"));

    if(userData && userData.uid) {
        if (request.handlerEnabled) {
            if (userData && exp && exp - new Date().getTime() / 1000 >= 300) {
                return request;
            } else {
                return axiosCall()
                    .then((tokenResponse) => {
                        const info = decode(tokenResponse);

                        localStorage.setItem("token", JSON.stringify(tokenResponse));
                        localStorage.setItem("expires_in", JSON.stringify(info.exp));
                        request.headers.Authorization = `Bearer ${tokenResponse}`;
                        return Promise.resolve(request);
                    }).catch(error => {
                        // decide what to do if you can't get your token
                        console.log("Error from response", error)
                    })
            }
        }
    }
    return request;
};

const store = createStore(
    rootReducers,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
);

const userInfo = JSON.parse(localStorage.getItem("user-data"));

if (userInfo) {
    const generalUserData = {
        accountId: userInfo.accountId,
        profileId: userInfo.profileId,
        picture: userInfo.picture,
        name: userInfo.name,
        email: userInfo.email,
        uid: userInfo.uid,
        token: userInfo.token,
        roleId: userInfo.roleId,
        isActive: userInfo.isActive,
        hireStatus: userInfo.hireStatus
    };
    store.dispatch(signInDispatch(generalUserData));
}

const roleInfo = JSON.parse(localStorage.getItem("check-role"));

if (roleInfo) {
    const payload = {
        role: roleInfo.role
    };
    store.dispatch(getRoleDispatch(payload));
}

const root = document.getElementById("root");
ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <Route component={App} />
            <ToastContainer />
        </Provider>
    </BrowserRouter>, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();