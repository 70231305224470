export const API = {
    firebaseEnv: `${process.env.REACT_APP_FIREBASE_END}`,
    getUserRole: `${process.env.REACT_APP_API_URL}users/check`,
    getWizardProfileSettings: `${process.env.REACT_APP_API_URL}users/profile/settings`,
    getWizardProfilePosition: `${process.env.REACT_APP_API_URL}users/profile/positions?position=`,
    getWizardProfileCities: `${process.env.REACT_APP_API_URL}users/profile/cities?name=`,
    getWizardProfileSkills: `${process.env.REACT_APP_API_URL}users/profile/skills?title=`,
    getProfile: `${process.env.REACT_APP_API_URL}users/profile`,
    getAccount: `${process.env.REACT_APP_API_URL}users/profile/account`,
    setAccount: `${process.env.REACT_APP_API_URL}users/accounts`,
    getResume: `${process.env.REACT_APP_API_URL}users/accounts/resume`,
    getAvatar: `${process.env.REACT_APP_API_URL}users/accounts/avatar`,
    getActivate: `${process.env.REACT_APP_API_URL}users/accounts/activate`,
    getDeActivate: `${process.env.REACT_APP_API_URL}users/accounts/deactivate`,
    getMail: `${process.env.REACT_APP_API_URL}users/profile/mailing`,
    getStopList: `${process.env.REACT_APP_API_URL}users/accounts/stoplist`,
    getHires: `${process.env.REACT_APP_API_URL}users/accounts/hires`,
    getHiresOffers: `${process.env.REACT_APP_API_URL}users/accounts/hires/offers`,
    getHiresNotify: `${process.env.REACT_APP_API_URL}users/accounts/hires/notify`,
    getSwitch: `${process.env.REACT_APP_API_URL}users/accounts/switch`,
    getRecruiterProfile: `${process.env.REACT_APP_API_URL}users/recruiter/profile`,
    getRecruiterTeam: `${process.env.REACT_APP_API_URL}users/recruiter/team`,
    leaveTeam: `${process.env.REACT_APP_API_URL}users/recruiter/team/leave`,
    joinTeam: `${process.env.REACT_APP_API_URL}users/recruiter/team/profile`,
    getSubscriptions: `${process.env.REACT_APP_API_URL}users/recruiter/subscriptions`,
    getStatistics: `${process.env.REACT_APP_API_URL}users/recruiter/statistics`,
    getSettings: `${process.env.REACT_APP_API_URL}users/recruiter/notifications`,
    getHire: `${process.env.REACT_APP_API_URL}users/recruiter/hire`,
    getPayment: `${process.env.REACT_APP_API_URL}users/recruiter/hire/notification`,
    refreshUrl: `${process.env.REACT_APP_PUBLIC_URL}home/profile/team?join=`,
    getUserMessages : `${process.env.REACT_APP_API_URL}users/messages`,
    sendUserMessages : `${process.env.REACT_APP_API_URL}users/messages/chat`,
    getProfileShare: `${process.env.REACT_APP_API_URL}users/profile/share`,
    declineCandidateRequest: `${process.env.REACT_APP_API_URL}users/profile/decline`,
    approveCandidateRequest: `${process.env.REACT_APP_API_URL}users/profile/hire`,
    recentViews: `${process.env.REACT_APP_API_URL}dashboard/candidate/recent-views`,
    expectations: `${process.env.REACT_APP_API_URL}dashboard/candidate/expectations`,
    candidateVacancies: `${process.env.REACT_APP_API_URL}dashboard/candidate/vacancies`,
    getCategories: `${process.env.REACT_APP_API_URL}users/profile/categories`,
    putMessage: `${process.env.REACT_APP_API_URL}users/messages/chat/`,
    getVacancies: `${process.env.REACT_APP_API_URL}vacancies`,
    getJobs: `${process.env.REACT_APP_API_URL}jobs`,
    getCandidates: `${process.env.REACT_APP_API_URL}candidates`,
    getRecruiters: `${process.env.REACT_APP_API_URL}recruiters`,
    getVacancyRating: `${process.env.REACT_APP_API_URL}dashboard/candidate/offers`,
    getMyJobs: `${process.env.REACT_APP_API_URL}jobs/my`,
    myJobs: `${process.env.REACT_APP_API_URL}jobs`,
    recruiterAccount: `${process.env.REACT_APP_API_URL}recruiters/account`,
    getSalaries: `${process.env.REACT_APP_API_URL}salaries`,
    modulesFooter: `${process.env.REACT_APP_API_URL}modules/footer`,
};